import React from 'react'

const BlogCard = data => {
  console.log('data: ', data)
  const { postHeader, postContent, author, postedOn } = data.data
  return (
    <div>
      <h1>{postHeader}</h1>
      <h6>posted by: {author}</h6>
      <h6>posted date: {postedOn}</h6>
      <p>{postContent}</p>
    </div>
  )
}

export default BlogCard
