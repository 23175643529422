const Locations = () => {
  return (
    <>
      <div className='colums-2'>
        <div className='flex flex-col space-y-6 md:w-1/2'>Houston, TX</div>
        <div className='md:w-1/2'>
          <img src={require('../content/images/houston.jpg')} alt='' />
        </div>
        <div className='flex flex-col space-y-6 md:w-1/2'>San Diego, CA</div>
        <div className='md:w-1/2'>
          <img src={require('../content/images/sandiego.jpg')} alt='' />
        </div>
        <div className='flex flex-col space-y-6 md:w-1/2'>Tulsa, OK</div>
        <div className='md:w-1/2'>
          <img src={require('../content/images/tulsa.jpg')} alt='' />
        </div>
        <div className='flex flex-col space-y-6 md:w-1/2'>Riverside, CA</div>
        <div className='md:w-1/2'>
          <img src={require('../content/images/riverside.jpg')} alt='' />
        </div>
      </div>
    </>
  );
};

export default Locations;
