import React, { useState } from 'react';
import { Typography } from '@mui/material';

import ContactUs from './ContactUs';
import MainFeaturedPost from './MainFeaturedPost';
import Locations from './Locations';
import AboutUs from './AboutUs';
import Expertise from './Expertise';
import Insights from './Insights';
import Careers from './Careers';
import {
  posts,
  postsDetail,
  sidebar,
  sections,
  MainFeaturedPostContent,
} from '../content/content';
import Blog from './Blog';

const Home = ({ page }) => {
  switch (page) {
    case 'home':
      return (
        <>
          <div className='flex flex-col space-y-12 md:w-1/2'>
            <MainFeaturedPost
              name='Role of AI in Marketing in 2022'
              snippet='How AI will dramatically change marketing in 2022 and coming years '
              action='readmore'
            ></MainFeaturedPost>
          </div>
          <div className='md:w-1/2'>
            <img src={require('../content/images/ai.jpeg')} alt='' />
          </div>
        </>
      );
    case 'Contact Us':
      return <ContactUs />;
    case 'Locations':
      return <Locations />;
    case 'Blog':
      return <Blog />;
    case 'About Us':
      return <AboutUs />;
    case 'Expertise':
      return <Expertise />;
    case 'Insights':
      return <Insights />;
    case 'Careers':
      return <Careers />;
    default:
      return (
        <div>
          <Typography variant='h1'>This still needs to be build</Typography>
        </div>
      );
  }
};

export default Home;
