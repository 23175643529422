import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Stack, Paper, Typography, Link } from '@mui/material'

const Sidebar = props => {
  const { archives, description, social, title } = props
  return (
    <Grid item xs={12} md={4}>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          bgcolor: 'grey.200'
        }}
      >
        <Typography variant='h6' gutterBottom>
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </Paper>
      <Typography variant='h6' gutterBottom sx={{ mt: 3 }}>
        Archives
      </Typography>
      {archives.map(archive => (
        <Link
          display='block'
          variant='body1'
          href={archive.url}
          key={archive.title}
        >
          {archive.title}
        </Link>
      ))}
      <Typography variant='h6' gutterBottom sx={{ mt: 3 }}>
        Social
      </Typography>
      {social.map(network => (
        <Link
          display='block'
          variant='body1'
          href='#'
          key={network.name}
          sx={{ mb: 0.5 }}
        >
          <Stack>
            <network.icon />
            <span>{network.name}</span>
          </Stack>
        </Link>
      ))}
    </Grid>
  )
}

export default Sidebar
