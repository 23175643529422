import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant='body2' color='text.secondary'>
      {'Copyright © '}
      <Link color='inherit' href='https://mui.com/'>
        Nurturing Lab LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      style={{ backgroundColor: 'pink' }}
    >
      <CssBaseline />

      <Box
        component='footer'
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth='sm'>
          <Typography variant='body1'>Nurturing Lab Limited Company</Typography>
          <Typography>Headquarters in Houston, TX</Typography>
          <Typography>Phone: 346-831-1784, 714-307-7593</Typography>
          <Typography>inquiry@nurturinglab.com</Typography>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}
