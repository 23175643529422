import GitHubIcon from '@mui/icons-material/GitHub'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import post1 from './blogs/blog-post.1.md'
import post2 from './blogs/blog-post.2.md'
import post3 from './blogs/blog-post.3.md'

export const MainFeaturedPostContent = {
  title: 'Title of a longer featured blog post',
  description:
    "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
  image: 'https://source.unsplash.com/random',
  imageText: 'main image description',
  linkText: 'Continue reading…'
}

export const sections = [
  {
    title: 'Insights',
    url: '#'
  },
  {
    title: 'Expertise',
    url: '#'
  },
  {
    title: 'Blog',
    url: '#'
  },
  {
    title: 'Locations',
    url: '#'
  },
  {
    title: 'Careers',
    url: '#'
  },
  {
    title: 'About Us',
    url: '#'
  },
  {
    title: 'Contact Us',
    url: '#'
  }
]

export const postsDetail = [
  {
    type: 'Main',
    title: 'How important is to nurture your market',
    description: `Nurturing your customers is important in any sales cycle, especially in 
        business where sales cycle are very long`,
    image: 'https://source.unsplash.com/random',
    imageText: 'image coming soon',
    linkText: 'Continue reading...',
    content: 'We will put very good content here'
  },
  {
    type: 'Main',
    title: 'How important is to nurture your market',
    description: `Nurturing your customers is important in any sales cycle, especially in 
        business where sales cycle are very long`,
    image: 'https://source.unsplash.com/random',
    imageText: 'image coming soon',
    linkText: 'Continue reading...',
    content: 'We will put very good content here'
  },
  {
    type: 'Regular',
    title: 'How important is to nurture your market',
    description: `Nurturing your customers is important in any sales cycle, especially in 
        business where sales cycle are very long`,
    image: 'https://source.unsplash.com/random',
    imageText: 'image coming soon',
    linkText: 'Continue reading...',
    content: 'We will put very good content here'
  }
]

export const posts = [post1, post2, post3]

export const sidebar = {
  title: 'About',
  description: 'Read previous articles',
  archives: [
    {
      title: 'Feb 2022',
      url: '#'
    },
    {
      title: 'Jan 2022',
      url: '#'
    },
    {
      title: 'Dec 2021',
      url: '#'
    },
    {
      title: 'Nov 2021',
      url: '#'
    },
    {
      title: 'Oct 2021',
      url: '#'
    },
    {
      title: 'Sep 2021',
      url: '#'
    },
    {
      title: 'Aug 2021',
      url: '#'
    },
    {
      title: 'Jul 2021',
      url: '#'
    }
  ],
  social: [
    {
      name: 'GitHub',
      icon: GitHubIcon
    },
    {
      name: 'Twitter',
      icon: TwitterIcon
    },
    {
      name: 'Facebook',
      icon: FacebookIcon
    }
  ]
}
