import { Link } from 'react-router-dom'
const MainFeaturedPost = props => {
  const { name, snippet, action } = props
  console.log('props: ', props)
  return (
    <>
      <h1 className='max-w-md text-4xl font-bold text-center md:text-5xl md:text-left'>
        {name}
      </h1>
      <p className='max-w-sm text-center text-darkGrayishBlue md:text-left'>
        {snippet}
      </p>
      <div className='flex justify-center md:justify-start'>
        <a
          href={action}
          className='p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight'
        >
          Read More
        </a>
      </div>
    </>
  )
}

export default MainFeaturedPost
