import React, { useState, useEffect } from 'react'
import BlogCard from '../component/BlogCard'
import Sidebar from './Sidebar'
import { sidebar } from '../content/content'

const Blog = () => {
  const [blogs, setBlogs] = useState([])
  useEffect(() => {
    const getPosts = async () => {
      const request = await fetch('http://localhost:8000/getAllPosts')
      const result = await request.json()
      console.log('result: ', result)
      setBlogs(result)
    }
    getPosts()
  }, [])

  return (
    <div>
      {blogs.map(blog => (
        <BlogCard data={blog} key={blog._id} />
      ))}
      <div xs={2}>
        <Sidebar
          title={sidebar.title}
          description={sidebar.description}
          archives={sidebar.archives}
          social={sidebar.social}
        />
      </div>
    </div>
  )
}
export default Blog
