import React from 'react'
import { Container, TextField, Grid, Button } from '@mui/material'

const ContactUs = () => {
  return (
    <Grid container spacing={2} styles={{ flex: 1 }}>
      <Grid item sx={6}>
        <h2 className='max-w-md text-4xl font-bold text-center md:text-sxl md:text-left'>
          Call us at 714-307-7593 or 346-831-1784
        </h2>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              id='firstname'
              label='First Name'
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              id='lastname'
              label='Last Name'
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField variant='outlined' id='email' label='Email' required />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              id='phone'
              label='Phone Number'
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              id='phone'
              label='What burining problem we can help you solve'
              required
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained'>Submit</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ContactUs
