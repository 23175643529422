import { Routes, Route, Link } from 'react-router-dom'

import Header from './Header'

import { useState } from 'react'
import Home from './pages/Home'
import { sections } from './content/content'
import Footer from './component/Footer'

function App() {
  const [page, setPage] = useState('home')
  const displayPage = value => {
    console.log('value for page: ', value)
    setPage(value)
  }

  return (
    <div id='main-container' maxwidth='lg' spacing={2}>
      <Header
        id='main-header'
        title='Nurturing Lab'
        sections={sections}
        action={displayPage}
        xs={12}
      />

      <section id='hero'>
        <div className='container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row'>
          <Home page={page} />
        </div>
      </section>

      <div xs={12}>
        <Footer />
      </div>
    </div>
  )
}

export default App
